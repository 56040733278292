import React from "react";
import PropTypes from "prop-types";
import { inject, observer } from "mobx-react";
import { FontIcon, Text, mergeStyles } from "@fluentui/react";
import "./ErrorList.scss";
import { withRouter } from "../../utils/withRouter";
import { withTranslations } from "../../utils/withTranslations";

@withTranslations
@withRouter
@inject("rootStore")
@observer
export class ErrorList extends React.Component {
  static propTypes = {
    navigate: PropTypes.func,
    rootStore: PropTypes.object,
    t: PropTypes.func,
  };

  onClick = () => {
    const { navigate } = this.props;

    navigate("/taskpane.html", { replace: true });
  };

  renderErrors = () => {
    const { rootStore } = this.props;
    const { errorStore } = rootStore;
    const { errors } = errorStore;

    return errors.map((error) => (
      <div key={error.id} className="errors__item">
        <div>
          <Text>{error.text}</Text>
        </div>
      </div>
    ));
  };

  render() {
    const { rootStore, t } = this.props;
    const { errorStore } = rootStore;
    const { errors } = errorStore;

    const iconClass = mergeStyles({
      fontSize: 14,
    });

    return (
      <div className="ErrorList">
        <div className="ErrorList__back" onClick={this.onClick} onKeyDown={this.onClick} role="button" tabIndex={0}>
          <FontIcon aria-label="back" role="img" iconName="SkypeArrow" className={iconClass}></FontIcon>
          <Text className="back__text">{t("back")}</Text>
        </div>
        <div className="ErrorList__errors">
          {errors.length > 0 ? this.renderErrors() : <Text className="errors__noErrors">{t("noErrors")}</Text>}
        </div>
      </div>
    );
  }
}

export default ErrorList;
