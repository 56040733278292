import { endpoint } from "../../utils/endpoint";
import { axiosGet } from "../axiosRequests";

export const USER_LEADS_ENDPOINT = (userId) => endpoint(`api/v1.0/users/${userId}/leads`);

export async function getLeads(userId, params = {}, authToken, additionalHeaders = {}) {
  const response = await axiosGet(USER_LEADS_ENDPOINT(userId), params, authToken, additionalHeaders);

  return response.data;
}

export default {
  getLeads,
};
