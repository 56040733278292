import { endpoint } from "../utils/endpoint";
import { axiosGet } from "./axiosRequests";

export const LEAD_ENDPOINT = (leadId) => endpoint(`api/v1.0/leads/${leadId}`);

export async function getLead(leadId, params = {}, authToken, additionalHeaders = {}, response_type = "docgen") {
  const response = await axiosGet(LEAD_ENDPOINT(leadId), { response_type, ...params }, authToken, additionalHeaders);

  return response.data;
}

export default {
  getLead,
};
