import { action, makeObservable, observable } from "mobx";
import { decrypt, encrypt } from "../utils/encryption";
import { formatErrorMessage } from "../utils/common";
import * as authenticationService from "../requests/authentication";
import * as userCampaignsService from "../requests/users/campaigns";
import * as leadsService from "../requests/leads";
import * as usersService from "../requests/users";
import { ErrorStore } from "./ErrorStore";

export class RootStore {
  @observable bannerStore;
  @observable authToken;
  @observable isAuthenticated;
  @observable isLoading;
  @observable campaignId;
  @observable campaigns;
  @observable lead;
  @observable formattedLead;
  @observable leadNotFound;
  @observable user;
  @observable userId;

  constructor(bannerStore) {
    makeObservable(this);
    this.bannerStore = bannerStore;
    this.campaignId = null;
    this.isAuthenticated = false;
    this.lead = null;
    this.formattedLead = null;
    this.user = null;
    this.campaigns = [];
    this.leadNotFound = false;
    this.errorStore = new ErrorStore(this);

    const userAttributes = this.getUserAttributes();
    if (userAttributes.authToken && userAttributes.userId) {
      this.setAuthToken(decrypt(process.env.REACT_APP_OFFICE_AUTH_KEY, userAttributes.authToken));
      this.setUserId(userAttributes.userId);
      this.setIsAuthenticated(true);
    }
  }

  @action
  setCampaignId = (campaignId) => {
    this.campaignId = campaignId;
  };

  @action
  setCampaigns = (campaigns) => {
    this.campaigns = campaigns;
  };

  @action
  setIsAuthenticated = (isAuthenticated) => {
    this.isAuthenticated = isAuthenticated;
  };

  @action
  setIsLoading = (isLoading) => {
    this.isLoading = isLoading;
  };

  @action
  setLead(lead) {
    this.lead = lead;
  }

  @action
  setFormattedLead(formattedLead) {
    this.formattedLead = formattedLead;
  }

  @action
  setLeadNotFound(leadNotFound) {
    this.leadNotFound = leadNotFound;
  }

  @action
  setUserId = (userId) => {
    this.userId = userId;
  };

  @action
  setUser = (user) => {
    this.user = user;
  };

  @action
  setAuthToken = (authToken) => {
    this.authToken = authToken;
  };

  logoutUser = async () => {
    try {
      await authenticationService.signOutUser(this.authToken);
    } catch (err) {
      this.removeUserAttributes();
      this.setIsAuthenticated(false);
    } finally {
      this.removeUserAttributes();
      this.setIsAuthenticated(false);
    }
    this.removeUserAttributes();
    this.setIsAuthenticated(false);
    this.clearRootStore();
  };

  loginUser = async (payload) => {
    let successfulLogin = false;
    this.setIsLoading(true);
    try {
      const response = await authenticationService.signInUser(payload);

      const authToken = response.meta.auth_token;
      const user = response.user;

      this.setAuthToken(authToken);
      this.setUserId(user.id);
      this.setUser(user);
      this.storeUserAttributes(encrypt(process.env.REACT_APP_OFFICE_AUTH_KEY, authToken), user.id);

      this.setIsAuthenticated(true);
      successfulLogin = true;
    } catch (err) {
      this.setIsAuthenticated(false);
    }

    this.setIsLoading(false);
    return successfulLogin;
  };

  storeUserAttributes(authToken, userId) {
    localStorage.setItem("authToken", authToken);
    localStorage.setItem("userId", userId);
  }

  removeUserAttributes() {
    localStorage.removeItem("authToken");
    localStorage.removeItem("userId");
  }

  getUserAttributes() {
    const authToken = localStorage.getItem("authToken");
    const userId = localStorage.getItem("userId");
    return { authToken, userId };
  }

  @action
  clearRootStore() {
    this.setUser(null);
    this.setLead(null);
    this.setFormattedLead(null);
    this.setCampaignId(null);
    this.setCampaigns([]);
    this.bannerStore.setBanners([]);
    this.errorStore.setErrors([]);
  }

  @action
  async getDocgenLead(leadId) {
    try {
      const response = await leadsService.getLead(leadId, {}, this.authToken);
      this.setLead(response.lead.docgen_attributes);
      this.setLeadNotFound(false);
    } catch (error) {
      const errorMessage = error.response.data.errors
        ? formatErrorMessage(error.response.data.errors)
        : error.response.data.error;

      if (error.response.status == 404) {
        this.setLeadNotFound(true);
      } else {
        if (error.response.status == 401) {
          await this.logoutUser();
        }

        this.bannerStore.addBanner(errorMessage, "Error");
      }
    }
  }

  @action
  async getUser(userId) {
    try {
      const response = await usersService.getUser(userId, {}, this.authToken);
      this.setUser(response.users);
    } catch (error) {
      const errorMessage = error.response.data.errors
        ? formatErrorMessage(error.response.data.errors)
        : error.response.data.error;

      if (error.response.status == 401 || error.response.status == 404) {
        await this.logoutUser();
      }

      this.bannerStore.addBanner(errorMessage, "Error");
    }
  }

  @action
  async getUserCampaigns(userId) {
    try {
      const data = await userCampaignsService.getCampaigns(userId, { easydocs: true }, this.authToken);

      this.setCampaigns(data.campaigns);

      return data.campaigns;
    } catch (error) {
      const errorMessage = error.response.data.errors
        ? formatErrorMessage(error.response.data.errors)
        : error.response.data.error;

      if (error.response.status == 401) {
        await this.logoutUser();
      }

      this.bannerStore.addBanner(errorMessage, "Error");
    }
  }
}

export default RootStore;
