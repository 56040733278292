import React from "react";
import PropTypes from "prop-types";
import { Text } from "@fluentui/react";
import wmLogo from "../../../../../assets/wingmate-icon.svg";
import "./LoginHeader.scss";
import { withTranslations } from "../../../utils/withTranslations";

@withTranslations
export class LoginHeader extends React.Component {
  static propTypes = {
    t: PropTypes.func,
  };

  render() {
    const { t } = this.props;

    return (
      <section className="LoginHeader">
        <img width="90" height="90" src={wmLogo} alt="logo" title="logo" />
        <Text variant="xxLarge">{t("welcome")}</Text>
        <Text variant="large">{t("login")}</Text>
      </section>
    );
  }
}

export default LoginHeader;
