import React from "react";
import PropTypes from "prop-types";
import { inject, observer } from "mobx-react";
import { MessageBar, MessageBarType, Text } from "@fluentui/react";

@inject("bannerStore")
@observer
export class ErrorBanner extends React.Component {
  static propTypes = {
    bannerStore: PropTypes.object,
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    body: PropTypes.string,
  };

  closeBanner = () => {
    const { bannerStore, id } = this.props;
    bannerStore.deleteBanner(id);
  };

  render() {
    const { body } = this.props;

    return (
      <MessageBar
        messageBarType={MessageBarType.error}
        onDismiss={this.closeBanner}
        isMultiline={true}
        dismissButtonAriaLabel="Close"
      >
        <Text>{body}</Text>
      </MessageBar>
    );
  }
}

export default ErrorBanner;
