import React from "react";
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";

export function withRouter(Child) {
  return function WithRouterDecorator({ ...otherProps }) {
    const location = useLocation();
    const params = useParams();

    const [searchParams] = useSearchParams();

    const searchParamsDict = {};

    for (const entry of searchParams.entries()) {
      const [param, value] = entry;
      if (param.includes("[]")) {
        searchParamsDict[param.replace(/\[\]/g, "")] = searchParams.getAll(param);
      } else {
        searchParamsDict[param] = value;
      }
    }

    const navigate = useNavigate();

    return (
      <Child navigate={navigate} {...otherProps} location={location} searchParams={searchParamsDict} {...params} />
    );
  };
}
