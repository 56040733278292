import en from "./en.json";
import es from "./es.json";
import fr from "./fr.json";
import ptBR from "./ptBR.json";

const locales = {
  en: en,
  es: es,
  fr: fr,
  ptBR: ptBR,
};

export default locales;

// This file is automatically generated. No edits to it will be saved.
