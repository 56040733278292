import { action, makeObservable, observable } from "mobx";

export class ErrorStore {
  @observable errors;

  constructor(rootStore) {
    makeObservable(this);
    this.errors = [];
    this.rootStore = rootStore;
  }

  findError(errorId) {
    const error = this.errors.find((error) => error.id === errorId);
    return error;
  }

  @action
  setErrors(errors) {
    this.errors = errors;
  }
}

export default ErrorStore;
