import * as React from "react";
import PropTypes from "prop-types";
import { FontIcon, Link, Text, mergeStyles } from "@fluentui/react";
import { withRouter } from "../../../utils/withRouter";
import "./ErrorCount.scss";
import { inject, observer } from "mobx-react";

@withRouter
@inject("rootStore")
@observer
export class ErrorCount extends React.Component {
  static propTypes = {
    errors: PropTypes.array,
    navigate: PropTypes.func,
    rootStore: PropTypes.object,
  };

  onClick = () => {
    const { navigate, rootStore } = this.props;

    rootStore.bannerStore.setBanners([]);
    navigate("/taskpane.html/errors");
  };

  render() {
    const { errors } = this.props;

    const iconClass = mergeStyles({
      height: 16,
      width: 16,
      fontSize: 16,
    });

    return (
      errors.length > 0 && (
        <Link onClick={this.onClick} className="ErrorCount">
          <div className="ErrorCount__count">
            <FontIcon aria-label="Error" role="img" iconName="ErrorBadge" className={iconClass} />
            <Text className="count__text">{errors.length}</Text>
          </div>
        </Link>
      )
    );
  }
}

export default ErrorCount;
