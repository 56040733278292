import { END_FOR_REGEX, FOR_LOOP_REGEX } from "./constants";

export function checkStartForLoopSyntax(inputString) {
  const hasForLoop = FOR_LOOP_REGEX.test(inputString);
  const hasEndForLoop = END_FOR_REGEX.test(inputString);
  return hasForLoop && !hasEndForLoop;
}

export function checkEndForLoopSyntax(inputString) {
  const hasForLoop = FOR_LOOP_REGEX.test(inputString);
  const hasEndForLoop = END_FOR_REGEX.test(inputString);
  return !hasForLoop && hasEndForLoop;
}

export function formatErrorMessage(errorList) {
  let errorMessage = errorList.map((error) => Object.values(error)).join(". ");

  if (errorMessage) {
    errorMessage = errorMessage + ".";
  }

  return errorMessage;
}

export function replaceNullsWithEmptyString(obj) {
  for (const key in obj) {
    if (obj[key] === null) {
      obj[key] = "";
    } else if (typeof obj[key] === "object") {
      replaceNullsWithEmptyString(obj[key]);
    }
  }
}
