import React from "react";
import PropTypes from "prop-types";
import { Provider } from "mobx-react";
import { BannerLayer } from "../components/Banners/BannerLayer";
import { BannerStore } from "../stores/BannerStore";

export class BannerProvider extends React.Component {
  static propTypes = {
    children: PropTypes.node,
  };

  constructor(props) {
    super(props);

    this.bannerStore = new BannerStore();
  }

  render() {
    const { children } = this.props;
    return (
      <Provider bannerStore={this.bannerStore}>
        <BannerLayer />
        {children}
      </Provider>
    );
  }
}

export default BannerProvider;
