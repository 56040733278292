import { endpoint } from "../utils/endpoint";
import { axiosDelete, axiosPost } from "./axiosRequests";

export const SIGN_IN_ENDPOINT = endpoint("api/v1.0/users/sign_in");

export const SIGN_OUT_ENDPOINT = endpoint("api/v1.0/users/sign_out");

export async function signInUser(payload = {}) {
  const response = await axiosPost(SIGN_IN_ENDPOINT, payload);

  return response.data;
}

export async function signOutUser(authToken) {
  const response = await axiosDelete(SIGN_OUT_ENDPOINT, authToken);

  return response.data;
}

export default {
  signInUser,
  signOutUser,
};
