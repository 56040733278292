import * as React from "react";
import PropTypes from "prop-types";
import { inject, observer } from "mobx-react";
import { Persona, PersonaSize } from "@fluentui/react/lib/Persona";

@inject("rootStore")
@observer
export default class UserInfo extends React.Component {
  static propTypes = {
    rootStore: PropTypes.object,
  };

  async componentDidMount() {
    const { rootStore } = this.props;
    const { userId } = rootStore;
    await rootStore.getUser(userId);
  }

  render() {
    const { rootStore } = this.props;
    const { user } = rootStore;

    const userPersona = {};

    if (user) {
      userPersona["imageUrl"] = user.profile_image_url;
      userPersona["text"] = user.full_name;
      userPersona["secondaryText"] = user.email;
    }

    return <Persona {...userPersona} size={PersonaSize.size40} />;
  }
}
