import { endpoint } from "../../utils/endpoint";
import { axiosGet } from "../axiosRequests";

export const USER_CAMPAIGNS_ENDPOINT = (userId) => endpoint(`api/v1.0/users/${userId}/campaigns`);

export async function getCampaigns(userId, params = {}, authToken, additionalHeaders = {}) {
  const response = await axiosGet(USER_CAMPAIGNS_ENDPOINT(userId), params, authToken, additionalHeaders);

  return response.data;
}

export default {
  getCampaigns,
};
