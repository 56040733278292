import React from "react";
import { Outlet } from "react-router-dom";
import CreateDocumentButton from "../components/CreateDocumentButton/CreateDocumentButton";

export class LeadSearchRoute extends React.Component {
  render() {
    return (
      <div data-testid="LeadSearchRoute">
        <Outlet />
        <CreateDocumentButton />
      </div>
    );
  }
}

export default LeadSearchRoute;
