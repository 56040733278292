import { fromByteArray } from "base64-js";

/* global Office */

export async function getBase64String() {
  const sliceSize = 100000;
  const byteArray = await new Promise((resolve, reject) => {
    Office.context.document.getFileAsync(Office.FileType.Compressed, { sliceSize: sliceSize }, function (result) {
      if (result.status === Office.AsyncResultStatus.Failed) {
        reject(result.error);
      }
      getFileContents(result.value, resolve, reject);
    });
  });

  const base64string = fromByteArray(byteArray);
  return base64string;
}

function getFileContents(file, onSuccess, onError) {
  let expectedSliceCount = file.sliceCount;
  let fileSlices = [];
  let array = [];
  getFileContentsHelper();
  function getFileContentsHelper() {
    file.getSliceAsync(fileSlices.length, function (result) {
      if (result.status === Office.AsyncResultStatus.Failed) {
        file.closeAsync();
        onError(result.error);
      }
      fileSlices.push(result.value.data);
      if (fileSlices.length == expectedSliceCount) {
        file.closeAsync();
        fileSlices.forEach((slice) => {
          array = array.concat(slice);
        });
        onSuccess(array);
      } else {
        getFileContentsHelper();
      }
    });
  }
}
