import React from "react";
import PropTypes from "prop-types";
import { inject, observer } from "mobx-react";
import { Navigate, Outlet } from "react-router-dom";
import { withRouter } from "../utils/withRouter";
import Header from "../components/Header/Header";

@withRouter
@inject("rootStore")
@observer
export class AuthenticatedRoute extends React.Component {
  static propTypes = {
    rootStore: PropTypes.object,
    location: PropTypes.object,
  };

  render() {
    const { location, rootStore } = this.props;
    const { isAuthenticated } = rootStore;

    return (
      <div data-testid="AuthenticatedRoute">
        {isAuthenticated ? (
          <>
            <Header />
            <Outlet />
          </>
        ) : (
          <Navigate to="/taskpane.html/sign_in" state={location} />
        )}
      </div>
    );
  }
}

export default AuthenticatedRoute;
