import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import locales from "./locales/index";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";

const options = {
  order: ["querystring", "navigator"],
  lookupQuerystring: "lng",
};

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: "en",
    debug: true,
    detection: options,
    interpolation: {
      escapeValue: false,
    },
    resources: {
      en: {
        default: locales.en,
      },
      es: {
        default: locales.es,
      },
      fr: {
        default: locales.fr,
      },
      "pt-BR": {
        default: locales.ptBR,
      },
    },
  });

export default i18n;
