import * as React from "react";
import PropTypes from "prop-types";
import { inject, observer } from "mobx-react";
import { CommandBarButton } from "@fluentui/react";
import { withTranslations } from "../../utils/withTranslations";
import { getDocumentCompressed, loadParagraphs } from "../../utils/office/officeUtils";

/* global Word */

@withTranslations
@inject("rootStore")
@observer
export default class HeaderOptions extends React.Component {
  static propTypes = {
    rootStore: PropTypes.object,
    t: PropTypes.func,
  };

  download = async () => {
    const { rootStore, t } = this.props;

    if (typeof Word !== "undefined") {
      await Word.run(async (context) => {
        try {
          let sections = context.document.sections;

          const paragraphs = await loadParagraphs(sections, context);
          await context.sync();

          await this.replaceText(paragraphs);
          await context.sync();
        } catch (error) {
          rootStore.bannerStore.addBanner(t("downloadError"), "Error");
        }
      });
    }

    getDocumentCompressed();
  };

  logout = async () => {
    const { rootStore } = this.props;
    await rootStore.logoutUser();
  };

  replaceText = async (paragraphs) => {
    for (const sectionParagraphs of paragraphs) {
      sectionParagraphs.map((paragraph) => {
        if (paragraph.text) {
          paragraph.insertText(paragraph.text, Word.InsertLocation.replace);
        }
      });
    }
  };

  render() {
    const { t } = this.props;

    const overflowItems = [
      { key: "download", text: t("download"), onClick: this.download, iconProps: { iconName: "Download" } },
      { key: "logout", text: t("logout"), onClick: this.logout, iconProps: { iconName: "MoveToFolder" } },
    ];

    return (
      <CommandBarButton
        ariaLabel="More Options"
        menuIconProps={{ iconName: "More" }}
        menuProps={{ items: overflowItems }}
      />
    );
  }
}
