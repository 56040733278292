import * as React from "react";
import PropTypes from "prop-types";
import ReactJson from "react-json-view";
import { Text } from "@fluentui/react";
import LeadInfo from "./LeadInfo/LeadInfo";

import "./LeadSection.scss";
import { withTranslations } from "../../utils/withTranslations";

@withTranslations
export default class LeadSection extends React.Component {
  static propTypes = {
    campaign: PropTypes.object,
    formattedLead: PropTypes.object,
    lead: PropTypes.object,
    leadNotFound: PropTypes.bool,
    t: PropTypes.func,
    user: PropTypes.object,
  };

  renderLeadInfo = () => {
    const { campaign, formattedLead, lead, user } = this.props;
    const { pm_account } = user;

    if (pm_account) {
      return <ReactJson data-testid="LeadJson" name={false} enableClipboard={false} src={lead} />;
    } else {
      return <LeadInfo lead={formattedLead} campaign={campaign} />;
    }
  };

  render() {
    const { formattedLead, lead, leadNotFound, t, user } = this.props;
    const showLeadInfo = lead && formattedLead && !leadNotFound;
    const showLeadPlaceHolder = !lead && !formattedLead && !leadNotFound;

    return (
      <div className="LeadSection" data-testid="LeadSection">
        {showLeadInfo && user && this.renderLeadInfo()}

        {showLeadPlaceHolder && (
          <div className="LeadSection__placeholder" data-testid="LeadSection__placeholder">
            <Text className="placeholder-text">{t("leadPlaceholder")}</Text>
          </div>
        )}

        {leadNotFound && (
          <div className="LeadSection__error" data-testid="LeadSection__error">
            <Text className="error-text">{t("error")}</Text>
            <Text className="error-text">{t("leadNotFound")}</Text>
          </div>
        )}
      </div>
    );
  }
}
