import { endpoint } from "../utils/endpoint";
import { axiosGet } from "./axiosRequests";

export const USER_ENDPOINT = (userId) => endpoint(`api/v1.0/users/${userId}`);

export async function getUser(userId, params = {}, authToken, additionalHeaders = {}) {
  const response = await axiosGet(USER_ENDPOINT(userId), params, authToken, additionalHeaders);

  return response.data;
}

export default {
  getUser,
};
