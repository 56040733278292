import * as React from "react";
import PropTypes from "prop-types";
import { inject, observer } from "mobx-react";
import UserInfo from "../UserInfo/UserInfo";
import ErrorCount from "./ErrorCount/ErrorCount";
import HeaderOptions from "../HeaderOptions/HeaderOptions";

import "./Header.scss";

@inject("rootStore")
@observer
export default class Header extends React.Component {
  static propTypes = {
    rootStore: PropTypes.object,
  };

  render() {
    const { rootStore } = this.props;
    const { errorStore } = rootStore;
    const { errors } = errorStore;

    return (
      <div className="Header">
        <UserInfo />
        <div className="Header__controls">
          <ErrorCount errors={errors} />
          <HeaderOptions />
        </div>
      </div>
    );
  }
}
