import axios from "axios";

axios.defaults.xsrfCookieName = "CSRF-TOKEN";
axios.defaults.xsrfHeaderName = "X-CSRF-Token";
axios.defaults.withCredentials = true;

export async function axiosGet(endpoint, params = {}, authToken, additionalHeaders = {}) {
  const response = await axios.get(endpoint, {
    params,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "X-Auth-Token": authToken,
      ...additionalHeaders,
    },
  });

  return {
    headers: response.headers,
    data: response.data,
    status: response.status,
  };
}

export async function axiosPost(endpoint, payload, authToken, additionalHeaders = {}) {
  const response = await axios.post(endpoint, payload, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "X-Auth-Token": authToken,
      ...additionalHeaders,
    },
  });

  return response;
}

export async function axiosPut(endpoint, payload, authToken, additionalHeaders = {}) {
  const response = await axios.put(endpoint, payload, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "X-Auth-Token": authToken,
      ...additionalHeaders,
    },
  });

  return response;
}

export async function axiosDelete(endpoint, authToken, additionalHeaders = {}) {
  const response = await axios.delete(endpoint, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "X-Auth-Token": authToken,
      ...additionalHeaders,
    },
  });

  return response;
}
