import * as React from "react";
import PropTypes from "prop-types";
import { inject, observer } from "mobx-react";
import { withRouter } from "../../utils/withRouter";
import { Check, Link, List, PrimaryButton, Stack, Text, TextField } from "@fluentui/react";
import { LoginHeader } from "./LoginHeader/LoginHeader";
import "./Login.scss";
import { withTranslations } from "../../utils/withTranslations";

const ENTER = "Enter";

@withTranslations
@withRouter
@inject("rootStore")
@observer
export class Login extends React.Component {
  static propTypes = {
    location: PropTypes.object,
    navigate: PropTypes.func,
    rootStore: PropTypes.object,
    t: PropTypes.func,
  };

  constructor(props) {
    super(props);

    this.state = {
      id: "",
      password: "",
      message: "",
    };
  }

  fieldsNotFilled = () => {
    const { id, password } = this.state;

    const isFilled = id && password;

    return !isFilled;
  };

  onChange = (field) => {
    const newState = {};
    newState[field.target.id] = field.target.value;
    this.setState(newState);
  };

  onKeyDown = async (event) => {
    if (event.key === ENTER) {
      await this.signIn();
    }
  };

  signIn = async () => {
    const { id, password } = this.state;
    const { navigate, rootStore, t } = this.props;

    const payload = {
      user: {
        email: id,
        uuid: id,
        password,
      },
    };

    const loginSuccessful = await rootStore.loginUser(payload);

    if (loginSuccessful) {
      navigate("/taskpane.html");
    } else {
      this.setState({ message: t("invalidIdOrPassword") });
    }
  };

  onRenderCell = (item) => {
    return (
      <div className="benefits__item">
        <div className="icon">{item.icon}</div>
        <Text variant="medium">{item.message}</Text>
      </div>
    );
  };

  render() {
    const { message } = this.state;
    const { t } = this.props;

    const stackTokens = { childrenGap: 16, padding: 30 };
    const stackStyles = {
      horizontalAlign: "center",
      verticalAlign: "center",
    };

    const items = [
      {
        icon: <Check checked={true}></Check>,
        message: t("benefits.view"),
      },
      {
        icon: <Check checked={true}></Check>,
        message: t("benefits.create"),
      },
      {
        icon: <Check checked={true}></Check>,
        message: t("benefits.generate"),
      },
    ];

    return (
      <div className="Login">
        <Stack className="Login__fields" tokens={stackTokens} {...stackStyles}>
          <LoginHeader />
          <TextField
            id="id"
            className="field"
            data-testid="UserId"
            label={t("userIdOrEmail")}
            onChange={this.onChange}
            onKeyDown={this.onKeyDown}
          />
          <TextField
            id="password"
            className="field"
            data-testid="Password"
            label={t("password")}
            type="password"
            canRevealPassword
            revealPasswordAriaLabel="Show password"
            onChange={this.onChange}
            onKeyDown={this.onKeyDown}
          />
          <Text className="field fields__errorMessage" variant="medium">
            {message}
          </Text>
          <List className="Login__benefits" items={items} onRenderCell={this.onRenderCell}></List>
          <Text>
            {t("learnMore")}{" "}
            <Link className="footer__link" href="https://wingmateapp.com/simple-crm/#easy-doc" target="blank">
              Easydocs
            </Link>
          </Text>
          <div className="fields__footer">
            <div className="footer__submit">
              <PrimaryButton
                className="field"
                text={t("signIn")}
                onClick={this.signIn}
                allowDisabledFocus
                disabled={this.fieldsNotFilled()}
              />
            </div>
          </div>
        </Stack>
      </div>
    );
  }
}

export default Login;
