import * as React from "react";
import PropTypes from "prop-types";
import pluralize from "pluralize";
import { inject, observer } from "mobx-react";
import { v4 as uuid } from "uuid";
import { PrimaryButton } from "@fluentui/react";
import { Liquid } from "liquidjs";
import { getBase64String } from "../../utils/getBase64String";
import { replaceNullsWithEmptyString } from "../../utils/common";
import { END_FOR_REGEX, FOR_LOOP_REGEX, LIQUID_REGEX_PATTERN } from "../../utils/constants";
import {
  checkTableRowForLoop,
  deleteTableRows,
  formatRangeValues,
  getAllTextInDocument,
  getAllTextInWorkbook,
  getTableFromDocumentSegment,
  getTablesInWorksheet,
  loadParagraphs,
  setTableValues,
} from "../../utils/office/officeUtils";
import { withTranslations } from "../../utils/withTranslations";
import "./CreateDocumentButton.scss";

/* global Excel, Word */
@withTranslations
@inject("rootStore")
@observer
export default class CreateDocumentButton extends React.Component {
  static propTypes = {
    rootStore: PropTypes.object,
    t: PropTypes.func,
  };

  parseLiquidString = (liquidString) => {
    const { rootStore } = this.props;
    const { lead } = rootStore;
    let parsedString = "";
    let error;

    const leadCopy = JSON.parse(JSON.stringify(lead));

    const engine = new Liquid({
      strictVariables: true,
    });

    try {
      replaceNullsWithEmptyString(leadCopy);
      parsedString = engine.parseAndRenderSync(liquidString, leadCopy);
    } catch (err) {
      error = err;
    }

    return { parsedString, error };
  };

  resetTables = (documentSegments, originalTables) => {
    originalTables.map(async ({ segmentIndex, tableIndex, values }) => {
      const currentTable = getTableFromDocumentSegment(documentSegments[segmentIndex], tableIndex);

      const firstRowIndex = values.length;
      const numRows = currentTable.rows.items.length;
      deleteTableRows(currentTable, firstRowIndex, numRows - firstRowIndex);

      setTableValues(currentTable, values);
    });
  };

  parseTableRow = (row) => {
    const values = row.values[0];
    const lastIndex = values.length - 1;

    const forLoopString = values[0].match(FOR_LOOP_REGEX)[0];
    values[0] = values[0].replace(forLoopString, "").trim();

    const endForLoopString = values[lastIndex].match(END_FOR_REGEX)[0];
    values[lastIndex] = values[lastIndex].replace(endForLoopString, "").trim();

    const formattedArray = [forLoopString, ...values, endForLoopString];
    const parsedRow = JSON.parse(this.parseLiquidString(JSON.stringify(formattedArray)).parsedString);

    const newRows = [];
    for (let i = 0; i < parsedRow.length - 1; i += formattedArray.length - 1) {
      newRows.push(parsedRow.slice(i + 1, i + formattedArray.length - 1));
    }
    return newRows;
  };

  parseExcelTableForLoops = async (tables, worksheetIndex, context) => {
    const originalTables = [];
    for (const [tableIndex, table] of tables.entries()) {
      const tableRange = table.getDataBodyRange();
      tableRange.load("values");
      await context.sync();

      const properties = { segmentIndex: worksheetIndex, tableIndex, values: tableRange.values };

      for (const row of table.rows.items) {
        if (checkTableRowForLoop(row.values[0])) {
          const newRows = this.parseTableRow(row);

          if (newRows.length) {
            const entireRow = row.getRange().getEntireRow();

            newRows.slice(1, newRows.length).map(() => entireRow.insert("Down"));

            table.getDataBodyRange().set({ values: newRows });
          }
        }
      }

      originalTables.push(properties);
    }

    return originalTables;
  };

  findAllForLoopTagIndices = (row) => {
    return row.reduce(
      (result, value, index) => {
        if (FOR_LOOP_REGEX.test(value)) {
          result.startForLoopIndices.push(index);
        } else if (END_FOR_REGEX.test(value)) {
          result.endForLoopIndices.push(index);
        }
        return result;
      },
      { endForLoopIndices: [], startForLoopIndices: [] }
    );
  };

  insertSubRowAtIndex = (subRow, row, startingRows, startIndex, endIndex, useStartingRows) => {
    const length = Math.max(subRow.length, startingRows.length);

    const filledRows = Array.from({ length }, (_, index) => {
      const currRow = subRow.length > index ? subRow[index] : Array(endIndex - startIndex + 1).fill("");
      let filledRow = Array(row.length).fill("");

      if (useStartingRows && startingRows.length > index) {
        filledRow = startingRows[index];
      } else {
        filledRow = index > 0 ? filledRow : [...row];
      }

      filledRow.splice(startIndex, currRow.length, ...currRow);

      return filledRow;
    });

    return filledRows;
  };

  parseSheetRow = (row) => {
    const { endForLoopIndices, startForLoopIndices } = this.findAllForLoopTagIndices(row);

    let newRows = [[]];

    if (startForLoopIndices.length == 0) {
      const parsedRow = JSON.parse(this.parseLiquidString(JSON.stringify(row)).parsedString);
      return [parsedRow];
    }

    for (let i = 0; i < startForLoopIndices.length; i++) {
      const startForLoopIndex = startForLoopIndices[i];
      const endForLoopIndex = endForLoopIndices[i];

      const forLoopString = row[startForLoopIndex].match(FOR_LOOP_REGEX)[0];
      row[startForLoopIndex] = row[startForLoopIndex].replace(forLoopString, "").trim();

      const endForLoopString = row[endForLoopIndex].match(END_FOR_REGEX)[0];
      row[endForLoopIndex] = row[endForLoopIndex].replace(endForLoopString, "").trim();

      const formattedArray = [forLoopString, ...row.slice(startForLoopIndex, endForLoopIndex + 1), endForLoopString];
      let parsedRow = JSON.parse(this.parseLiquidString(JSON.stringify(formattedArray)).parsedString);

      if (parsedRow.length == 1 && parsedRow[0] == "") {
        parsedRow = Array(formattedArray.length).fill("");
      }

      let formattedParsedRow = [];
      for (let i = 0; i < parsedRow.length - 1; i += formattedArray.length - 1) {
        formattedParsedRow.push(parsedRow.slice(i + 1, i + formattedArray.length - 1));
      }

      newRows = this.insertSubRowAtIndex(formattedParsedRow, row, newRows, startForLoopIndex, endForLoopIndex, i > 0);
    }

    return newRows;
  };

  parseWorkSheetLiquid = async (worksheet, worksheetIndex, context) => {
    const tables = await getTablesInWorksheet(worksheet, context);

    const originalTables = await this.parseExcelTableForLoops(tables, worksheetIndex, context);
    await context.sync();

    let updatedUsedRange = worksheet.getUsedRange();
    updatedUsedRange.load("values");
    updatedUsedRange.load("columnCount");

    await context.sync();

    let newValues = updatedUsedRange.values.map((row) => {
      return this.parseSheetRow(row);
    });

    newValues = formatRangeValues(newValues);

    updatedUsedRange = updatedUsedRange.getAbsoluteResizedRange(newValues.length, updatedUsedRange.columnCount);
    updatedUsedRange.load("address");
    updatedUsedRange.load("values");

    await context.sync();

    const originalValues = updatedUsedRange.values;
    updatedUsedRange.values = newValues;

    return { address: updatedUsedRange.address, values: originalValues, originalTables };
  };

  parseExcelLiquid = async () => {
    const { rootStore, t } = this.props;
    try {
      await Excel.run(async (context) => {
        const originalValues = [];
        const worksheets = context.workbook.worksheets;
        worksheets.load("items");
        await context.sync();

        let originalTables = [];

        for (const [worksheetIndex, worksheet] of worksheets.items.entries()) {
          const values = await this.parseWorkSheetLiquid(worksheet, worksheetIndex, context);
          originalTables = [...originalTables, ...values.originalTables];
          originalValues.push(values);
        }

        await context.sync();

        const base64string = await getBase64String();

        Excel.createWorkbook(base64string);

        worksheets.items.forEach((worksheet, index) => {
          worksheet.getRange(originalValues[index].address).values = originalValues[index].values;
        });

        worksheets.items.map((worksheet) => {
          return worksheet.tables.load("rows/values");
        });
        await context.sync();

        this.resetTables(worksheets.items, originalTables);
      });
    } catch (error) {
      rootStore.bannerStore.addBanner(t("unexpectedError"), "Error");
    }
  };

  parseParagraphs = async (paragraphs) => {
    for (const sectionParagraphs of paragraphs) {
      sectionParagraphs.map((paragraph) => {
        const result = this.parseLiquidString(paragraph.text).parsedString;
        const resultSameParagraph = result.split("\n").join(" ");
        paragraph.insertText(resultSameParagraph, Word.InsertLocation.replace);
      });
    }
  };

  resetParagraphs = (originalParagraphs, paragraphs) => {
    for (let i = 0; i < paragraphs.length; i++) {
      paragraphs[i].forEach((paragraph, index) => {
        paragraph.insertText(originalParagraphs[i][index].text, Word.InsertLocation.replace);
      });
    }
  };

  parseWordTableForLoops = async (sections, context) => {
    sections.load("items");
    await context.sync();

    const originalTables = [];

    for (const [segmentIndex, section] of sections.items.entries()) {
      const body = section.body;
      body.tables.load("rows, values");

      await context.sync();

      for (const [tableIndex, table] of body.tables.items.entries()) {
        for (const row of table.rows.items) {
          if (checkTableRowForLoop(row.values[0])) {
            originalTables.push({ values: table.values, tableIndex, segmentIndex });
            const newRows = this.parseTableRow(row);

            if (newRows.length) {
              row.set({ values: [newRows[0]] });
              if (newRows.length > 1) {
                row.insertRows(Word.InsertLocation.after, newRows.length - 1, newRows.slice(1, newRows.length));
              }
            }
            await context.sync();
          }
        }
      }
    }
    return originalTables;
  };

  parseWordLiquid = async () => {
    const { rootStore, t } = this.props;
    await Word.run(async (context) => {
      try {
        let sections = context.document.sections;

        const originalTables = await this.parseWordTableForLoops(sections, context);
        await context.sync();

        const paragraphs = await loadParagraphs(sections, context);
        const originalParagraphs = JSON.parse(JSON.stringify(paragraphs));
        await context.sync();

        await this.parseParagraphs(paragraphs);
        await context.sync();

        const base64string = await getBase64String();

        this.resetParagraphs(originalParagraphs, paragraphs);

        sections = context.document.sections;
        sections.load("items");
        await context.sync();

        for (const section of sections.items) {
          const body = section.body;
          body.tables.load("rows, values");
        }
        await context.sync();

        this.resetTables(sections.items, originalTables);

        context.application.createDocument(base64string).open();
      } catch (error) {
        rootStore.bannerStore.addBanner(t("unexpectedError"), "Error");
      }
    });
  };

  findErrorsInLiquid = (liquidStringList) => {
    const errors = [];
    for (const liquidTag of liquidStringList) {
      const result = this.parseLiquidString(liquidTag);

      if (result.error) {
        const errorText =
          result.error.name == "RenderError"
            ? `{{${result.error.token.content}}} not found`
            : result.error.message.split(",")[0];
        errors.push({ id: uuid(), text: errorText });
      }
    }

    return errors;
  };

  showLiquidErrors = (errors) => {
    const { rootStore } = this.props;
    const { errorStore } = rootStore;

    errorStore.setErrors(errors);

    if (errors.length > 0) {
      rootStore.bannerStore.addBanner(`${errors.length} ${pluralize("errors", errors.length)} found`, "LiquidError");
    }
  };

  isExcelLiquidValid = async () => {
    const { rootStore, t } = this.props;
    let liquidValid = true;

    await Excel.run(async (context) => {
      try {
        const workbookText = await getAllTextInWorkbook(context);

        const allLiquidTagsMatched = workbookText.match(LIQUID_REGEX_PATTERN) || [];

        const errors = this.findErrorsInLiquid(allLiquidTagsMatched);

        if (errors.length > 0) {
          this.showLiquidErrors(errors);
          liquidValid = false;
        }
      } catch (err) {
        rootStore.bannerStore.addBanner(t("unexpectedError"), "Error");
        liquidValid = false;
      }
    });

    return liquidValid;
  };

  isWordLiquidValid = async () => {
    const { rootStore, t } = this.props;
    let liquidValid = true;

    await Word.run(async (context) => {
      try {
        const documentText = await getAllTextInDocument(context);

        const allLiquidTagsMatched = documentText.match(LIQUID_REGEX_PATTERN) || [];

        const errors = this.findErrorsInLiquid(allLiquidTagsMatched);

        if (errors.length > 0) {
          this.showLiquidErrors(errors);
          liquidValid = false;
        }
      } catch (error) {
        rootStore.bannerStore.addBanner(t("unexpectedError"), "Error");
        liquidValid = false;
      }
    });

    return liquidValid;
  };

  parseLiquid = async () => {
    const { rootStore } = this.props;
    let parseLiquid, documentLiquidVerified;

    if (typeof Excel !== "undefined") {
      parseLiquid = this.parseExcelLiquid;

      documentLiquidVerified = await this.isExcelLiquidValid();
    } else if (typeof Word !== "undefined") {
      parseLiquid = this.parseWordLiquid;

      documentLiquidVerified = await this.isWordLiquidValid();
    }

    if (documentLiquidVerified) {
      rootStore.bannerStore.setBanners([]);
      rootStore.errorStore.setErrors([]);
      parseLiquid();
    }
  };

  render() {
    const { rootStore, t } = this.props;
    const { lead } = rootStore;

    return (
      <div className="CreateDocumentButton">
        <div className="CreateDocument__buttonWrapper">
          <PrimaryButton
            className="CreateDocumentButton__button"
            text={t("createDocument")}
            onClick={this.parseLiquid}
            disabled={!lead}
          />
        </div>
      </div>
    );
  }
}
