import * as React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { Image, ImageFit, List, Text } from "@fluentui/react";

import "./LeadInfo.scss";
import { withTranslations } from "../../../utils/withTranslations";

@withTranslations
export default class LeadInfo extends React.Component {
  static propTypes = {
    lead: PropTypes.object,
    campaign: PropTypes.object,
    t: PropTypes.func,
  };

  formatDateTime = (string) => {
    const date = moment(new Date(string));
    return date.format("MMM D, YYYY, h:mm A");
  };

  formatDate = (string) => {
    const date = moment(new Date(string));
    return date.format("MMM DD, YYYY");
  };

  onRenderCell = (leadField) => {
    return (
      <div className="LeadInfo__field">
        <Text>{leadField[0]}</Text>
        <Text variant="smallPlus">{leadField[1]}</Text>
      </div>
    );
  };

  getPhotoUrl = (photoUrls) => {
    if (photoUrls.length >= 1) {
      return photoUrls[0];
    }
    return null;
  };

  formatCustomFields = (fields) => {
    const { campaign } = this.props;
    const { custom_fields } = campaign;
    const formattedFields = fields.map(([key, value]) => {
      const campaignCustomField = custom_fields.find((field) => field.id === key);
      let formattedValue;
      if (campaignCustomField.type === "date") {
        formattedValue = this.formatDate(value);
      } else if (campaignCustomField.type === "datetime") {
        formattedValue = this.formatDateTime(value);
      } else if (campaignCustomField.type === "table") {
        formattedValue = null;
      } else formattedValue = value;
      return [campaignCustomField.name, formattedValue];
    });

    return formattedFields;
  };

  createLeadFieldItems = (customFields) => {
    const fields = Object.entries(customFields);
    const formattedFields = this.formatCustomFields(fields);
    const fieldsWithValues = formattedFields.filter(
      (value) => value[1] !== undefined && value[1] !== null && value[1] !== ""
    );
    return fieldsWithValues;
  };

  render() {
    const { lead, t } = this.props;

    const { address, amount, customFields, name, photoUrls, status } = lead;

    const leadItems = this.createLeadFieldItems(customFields);

    leadItems.unshift(["Address", address], ["Value ($)", amount + "0"], ["Status", status]);

    const photoUrl = this.getPhotoUrl(photoUrls);

    return (
      <div className="LeadInfo" data-testid="LeadInfo">
        <Text className="LeadInfo__header" variant="large">
          {name}
        </Text>
        {photoUrl && <Image className="LeadInfo__image" src={photoUrl} imageFit={ImageFit.centerContain} />}
        <div className="LeadInfo__fieldsSection">
          <Text className="fieldsSection__title" variant="medium">
            {t("leadInfo")}:
          </Text>
          <List className="LeadInfo__fields" items={leadItems} onRenderCell={this.onRenderCell} />
        </div>
      </div>
    );
  }
}
