import React from "react";
import PropTypes from "prop-types";
import { inject, observer } from "mobx-react";
import { ErrorBanner } from "./ErrorBanner/ErrorBanner";
import { LiquidErrorBanner } from "./LiquidErrorBanner/LiquidErrorBanner";
import "./BannerLayer.scss";

/** Global Office */

@inject("bannerStore")
@observer
export class BannerLayer extends React.Component {
  static propTypes = {
    bannerStore: PropTypes.object,
  };

  renderBanners = () => {
    const { bannerStore } = this.props;
    const { banners } = bannerStore;

    return banners.map((banner) => (
      <div key={banner.id}>
        {banner.type == "Error" && <ErrorBanner key={banner.id} id={banner.id} body={banner.body} />}
        {banner.type == "LiquidError" && <LiquidErrorBanner key={banner.id} id={banner.id} body={banner.body} />}
      </div>
    ));
  };

  render() {
    return (
      <div className="BannerLayer" data-testid="banner-column">
        {this.renderBanners()}
      </div>
    );
  }
}

export default BannerLayer;
