import * as React from "react";
import PropTypes from "prop-types";

import "./LeadSearchItem.scss";
import { ActionButton } from "@fluentui/react";
export default class LeadSearchItem extends React.Component {
  static propTypes = {
    lead: PropTypes.object,
    onClick: PropTypes.func,
  };

  onClick = () => {
    const { lead, onClick } = this.props;
    onClick(lead);
  };

  render() {
    const { lead } = this.props;

    return (
      <ActionButton className="LeadSearchItem" onClick={this.onClick}>
        {lead.name}
      </ActionButton>
    );
  }
}
