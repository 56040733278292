import { checkEndForLoopSyntax, checkStartForLoopSyntax } from "../common";
import { saveAs } from "file-saver";

/* global Office */

export function checkTableRowForLoop(rowValues) {
  const lastIndex = rowValues.length - 1;
  return checkStartForLoopSyntax(rowValues[0]) && checkEndForLoopSyntax(rowValues[lastIndex]);
}

export function deleteTableRows(table, firstRowIndex, numRowsToDelete) {
  if (typeof Excel !== "undefined" && numRowsToDelete > 0) {
    const firstRow = table.rows.items[firstRowIndex].getRange().getEntireRow();

    if (numRowsToDelete > 1) {
      firstRow.getRowsBelow(numRowsToDelete - 1).delete("Up");
    }

    firstRow.delete("Up");
  } else if (typeof Word !== "undefined" && numRowsToDelete > 0) {
    table.deleteRows(firstRowIndex, numRowsToDelete);
  }
}

function downloadFile(fileData, fileName) {
  const blob = new Blob([fileData], {
    type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  });
  saveAs(blob, fileName);
}

export function getDocumentCompressed() {
  Office.context.document.getFileAsync(Office.FileType.Compressed, {}, function (result) {
    if (result.status == "succeeded") {
      const myFile = result.value;
      const sliceCount = myFile.sliceCount;
      const docdataSlices = [];
      let slicesReceived = 0,
        gotAllSlices = true;

      getSliceAsync(myFile, 0, sliceCount, gotAllSlices, docdataSlices, slicesReceived);
    }
  });
}

function getSliceAsync(file, nextSlice, sliceCount, gotAllSlices, docdataSlices, slicesReceived) {
  file.getSliceAsync(nextSlice, function (sliceResult) {
    if (sliceResult.status == "succeeded") {
      if (!gotAllSlices) {
        return;
      }

      docdataSlices[sliceResult.value.index] = sliceResult.value.data;

      if (++slicesReceived == sliceCount) {
        file.closeAsync();
        onGotAllSlices(docdataSlices);
      } else {
        getSliceAsync(file, ++nextSlice, sliceCount, gotAllSlices, docdataSlices, slicesReceived);
      }
    } else {
      gotAllSlices = false;
      file.closeAsync();
    }
  });
}

function combineSlices(docdataSlices) {
  const fileData = new Uint8Array(docdataSlices.reduce((acc, slice) => acc.concat(Array.from(slice)), [])).buffer;
  return fileData;
}

function loadFileName() {
  return new Promise((resolve) => {
    Office.context.document.getFilePropertiesAsync((res) => {
      if (res && res.value && res.value.url) {
        const url = new URL(res.value.url);
        const name = url.pathname.split("/").pop();
        resolve(name);
      }
      resolve("");
    });
  });
}

function onGotAllSlices(docdataSlices) {
  const fileData = combineSlices(docdataSlices);

  loadFileName().then((fileName) => {
    downloadFile(fileData, fileName || "downloaded_doc.docx");
  });
}

export function formatRangeValues(inputArray) {
  return inputArray.reduce((transformedArray, subArray) => {
    if (subArray.length === 1) {
      return transformedArray.concat(subArray);
    } else {
      return transformedArray.concat(subArray);
    }
  }, []);
}

export async function getAllTextInDocument(context) {
  const sections = context.document.sections;
  sections.load("items");

  await context.sync();

  const { bodies, footers, headers } = sections.items.reduce(
    (result, section) => {
      const header = section.getHeader("primary");
      header.load("text");
      result.headers.push(header);

      const body = section.body;
      body.load("text");
      result.bodies.push(body);

      const footer = section.getFooter("primary");
      footer.load("text");
      result.footers.push(footer);

      return result;
    },
    { headers: [], bodies: [], footers: [] }
  );

  await context.sync();

  const textArray = await sections.items.reduce((previousTextArray, section, index) => {
    const header = headers[index];

    const body = bodies[index];

    const footer = footers[index];

    return [...previousTextArray, header.text, body.text, footer.text];
  }, []);

  await context.sync();

  return textArray.join("");
}

export async function getAllTextInWorkbook(context) {
  const worksheets = context.workbook.worksheets;
  worksheets.load("items");

  await context.sync();

  const usedRanges = worksheets.items.reduce((result, worksheet) => {
    const usedRange = worksheet.getUsedRange();
    usedRange.load("values");
    usedRange.load("address");

    result.push(usedRange);

    return result;
  }, []);

  await context.sync();

  const textArray = await worksheets.items.reduce((resultTextArray, _, index) => {
    const usedRange = usedRanges[index];

    const rangeText = usedRange.values.reduce((resultRangeText, row) => {
      return [...resultRangeText, ...row];
    }, []);

    return [...resultTextArray, ...rangeText];
  }, []);

  return textArray.join("");
}

export function getTableFromDocumentSegment(segment, tableIndex) {
  if (typeof Excel !== "undefined") {
    return segment.tables.items[tableIndex];
  } else if (typeof Word !== "undefined") {
    return segment.body.tables.items[tableIndex];
  }
}

export async function getTablesInWorksheet(worksheet, context) {
  const usedRange = worksheet.getUsedRange();
  usedRange.load("values");
  usedRange.load("address");

  const tables = worksheet.tables;
  tables.load("rows");
  await context.sync();

  return tables.items;
}

export async function loadParagraphs(sections, context) {
  const paragraphs = [];

  sections.load("items");
  await context.sync();

  for (const section of sections.items) {
    const header = section.getHeader("primary");
    header.load("paragraphs/text");

    const body = section.body;
    body.load("paragraphs/text");

    const footer = section.getFooter("primary");
    footer.load("paragraphs/text");

    await context.sync();

    const items = [...header.paragraphs.items, ...body.paragraphs.items, ...footer.paragraphs.items];
    paragraphs.push(items);
  }
  return paragraphs;
}

export function setTableValues(table, values) {
  if (typeof Excel !== "undefined") {
    table.getDataBodyRange().set({ values });
  } else if (typeof Word !== "undefined") {
    table.set({ values });
  }
}
