import App from "./App";
import { initializeIcons } from "@fluentui/font-icons-mdl2";
import React from "react";
import ReactDOM from "react-dom";

import i18n from "./i18n";
import { I18nextProvider } from "react-i18next";

/* global Office */

initializeIcons();

let isOfficeInitialized = false;

const title = "Wingmate EasyDocs";

const render = (Component) => {
  ReactDOM.render(
    <React.StrictMode>
      <I18nextProvider i18n={i18n}>
        <Component title={title} isOfficeInitialized={isOfficeInitialized} />
      </I18nextProvider>
    </React.StrictMode>,
    document.getElementById("container")
  );
};

/* Render application after Office initializes */
Office.onReady(() => {
  isOfficeInitialized = true;
  render(App);
});

/* Initial render showing a progress bar */
render(App);
