import * as React from "react";
import PropTypes from "prop-types";
import { Dropdown } from "@fluentui/react/lib/Dropdown";
import { ResponsiveMode } from "@fluentui/react";
import { withTranslations } from "../../../utils/withTranslations";

@withTranslations
export default class CampaignSelect extends React.Component {
  static propTypes = {
    activeCampaign: PropTypes.number,
    campaigns: PropTypes.array,
    onChange: PropTypes.func,
    t: PropTypes.func,
  };

  onChange = (event, item) => {
    const { onChange } = this.props;
    onChange(item);
  };

  render() {
    const { activeCampaign, campaigns, t } = this.props;

    const selectOptions = campaigns.map((campaign) => {
      return {
        key: campaign.id,
        text: campaign.name,
      };
    });

    return (
      <Dropdown
        className="CampaignSelect"
        selectedKey={activeCampaign}
        onChange={this.onChange}
        placeholder={t("selectACampaign")}
        options={selectOptions}
        responsiveMode={ResponsiveMode.large}
      />
    );
  }
}
