import { action, makeObservable, observable } from "mobx";
import { v4 as uuid } from "uuid";

export class BannerStore {
  @observable banners;

  constructor() {
    makeObservable(this);
    this.banners = [];
  }

  @action
  setBanners(banners) {
    this.banners = banners;
  }

  @action
  addBanner(body, type) {
    const id = uuid();
    this.banners.push({ id, body, type });
  }

  @action
  deleteBanner(bannerId) {
    this.banners = this.banners.filter((banner) => banner.id !== bannerId);
  }
}

export default BannerStore;
