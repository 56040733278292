import React from "react";
import PropTypes from "prop-types";
import { Provider, inject, observer } from "mobx-react";
import { RootStore } from "../stores/RootStore";

@inject("bannerStore")
@observer
export class RootProvider extends React.Component {
  static propTypes = {
    bannerStore: PropTypes.object,
    children: PropTypes.node,
  };

  constructor(props) {
    super(props);

    this.rootStore = new RootStore(props.bannerStore);
  }

  render() {
    const { children } = this.props;
    return <Provider rootStore={this.rootStore}>{children}</Provider>;
  }
}

export default RootProvider;
