import * as React from "react";
import PropTypes from "prop-types";
import { inject, observer } from "mobx-react";
import * as userLeadsService from "../../requests/users/leads";
import { List, SearchBox, Text } from "@fluentui/react";
import { withRouter } from "../../utils/withRouter";
import { withTranslations } from "../../utils/withTranslations";
import { formatErrorMessage } from "../../utils/common";
import CampaignSelect from "./CampaignSelect/CampaignSelect";
import LeadSearchItem from "./LeadSearchItem";
import LeadSection from "../LeadSection/LeadSection";
import "./LeadSearch.scss";

const ESCAPE = "Escape";
const SEARCH_DELAY = 1000;

@withTranslations
@withRouter
@inject("rootStore")
@observer
export default class LeadSearch extends React.Component {
  static propTypes = {
    navigate: PropTypes.func,
    rootStore: PropTypes.object,
    t: PropTypes.func,
  };

  constructor(props) {
    super(props);

    this.state = {
      campaigns: [],
      leads: [],
      noLeadsFound: false,
      searchValue: "",
    };

    this.timeout = null;

    this.searchRef = React.createRef();
    this.listRef = React.createRef();
  }

  async componentDidMount() {
    const { navigate, rootStore } = this.props;
    const { userId } = rootStore;

    const campaigns = await rootStore.getUserCampaigns(userId);

    if (!campaigns.length) {
      navigate("/taskpane.html/not_enabled");
    }
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  setActiveCampaign = async (campaign) => {
    const { rootStore } = this.props;
    rootStore.setLead(null);
    rootStore.setFormattedLead(null);
    rootStore.setCampaignId(campaign.key);
    this.setState({ searchValue: "" });
  };

  handleClickOutside = (event) => {
    const clickNotInList = this.listRef && !this.listRef.current.contains(event.target);
    const clickNotInSearch = this.searchRef && !this.searchRef.current.contains(event.target);
    if (clickNotInList && clickNotInSearch) {
      this.setState({ leads: [] });
    }
  };

  onClear = () => {
    this.setState({ leads: [], searchValue: "" });
    document.removeEventListener("mousedown", this.handleClickOutside);
  };

  onClick = async () => {
    const { leads, searchValue } = this.state;
    const { rootStore } = this.props;
    const { authToken, bannerStore, campaignId, userId } = rootStore;

    if (leads.length === 0) {
      try {
        const params = {
          campaign_id: campaignId,
          search: searchValue,
        };
        const response = await userLeadsService.getLeads(userId, params, authToken);
        const leads = response.leads;
        this.setState({ leads });
        document.addEventListener("mousedown", this.handleClickOutside);
      } catch (error) {
        const errorMessage = error.response.data.errors
          ? formatErrorMessage(error.response.data.errors)
          : error.response.data.error;

        if (error.response.status == 401) {
          await rootStore.logoutUser();
        }

        bannerStore.addBanner(errorMessage, "Error");
      }
    }
  };

  onSearch = async (event, value) => {
    const { rootStore } = this.props;
    const { authToken, campaignId, userId } = rootStore;
    this.setState({ searchValue: value });
    clearTimeout(this.delayTimeout);
    this.delayTimeout = setTimeout(async () => {
      if (event.key !== ESCAPE) {
        const params = {
          campaign_id: campaignId,
          search: value,
        };
        const response = await userLeadsService.getLeads(userId, params, authToken);
        const leads = response.leads;
        this.setState({ leads, noLeadsFound: leads.length == 0 ? true : false });
      }
    }, SEARCH_DELAY);
  };

  handleItemClick = async (lead) => {
    const { rootStore } = this.props;
    await rootStore.getDocgenLead(lead.id);
    rootStore.setFormattedLead(lead);
    this.setState({ leads: [], searchValue: lead.name });
  };

  onRenderCell = (lead) => {
    return <LeadSearchItem lead={lead} onClick={this.handleItemClick} key={lead.identifier} />;
  };

  render() {
    const { leads, noLeadsFound, searchValue } = this.state;
    const { rootStore, t } = this.props;
    const { campaignId, campaigns, formattedLead, lead, leadNotFound, user } = rootStore;

    const formattedLeads = leads.map((lead) => {
      return {
        name: lead.identifier,
        id: lead.id,
        amount: lead.amount,
        address: lead.address,
        customFields: lead.custom_fields,
        status: lead.status_string,
        photoUrls: lead.photo_urls,
      };
    });

    return (
      <div className="LeadSearch" data-testid="LeadSearch">
        <CampaignSelect activeCampaign={campaignId} campaigns={campaigns} onChange={this.setActiveCampaign} />
        <div className="LeadSearch__search">
          <SearchBox
            className="search__searchBox"
            value={searchValue}
            onClick={this.onClick}
            placeholder={t("searchLeads")}
            onChange={this.onSearch}
            onClear={this.onClear}
            disabled={campaignId === null}
            ref={this.searchRef}
          />
          {noLeadsFound && <Text>{t("leadsNotFound")}</Text>}
          <div ref={this.listRef}>
            <List className="search__list" items={formattedLeads} onRenderCell={this.onRenderCell} />
          </div>
        </div>
        <LeadSection
          lead={lead}
          formattedLead={formattedLead}
          leadNotFound={leadNotFound}
          user={user}
          campaign={campaigns.find((campaign) => campaign.id === campaignId)}
        />
      </div>
    );
  }
}
