import React from "react";
import PropTypes from "prop-types";
import { Link, Text } from "@fluentui/react";
import "./RequestEasyDocs.scss";
import { withTranslations } from "../../utils/withTranslations";

@withTranslations
export default class RequestEasyDocs extends React.Component {
  static propTypes = {
    t: PropTypes.func,
  };

  render() {
    const { t } = this.props;

    return (
      <div className="RequestEasyDocs">
        <Text variant="large">{t("access.easyDocsNotEnabled")}</Text>
        <Text variant="medium">{t("access.requestMeeting")}</Text>
        <Text variant="large">
          <Link href="https://calendly.com/wingmate-dev/easydocs-setup" target="_blank">
            {t("contactWingmate")}
          </Link>
        </Text>
      </div>
    );
  }
}
