import React from "react";
import PropTypes from "prop-types";
import { inject, observer } from "mobx-react";
import { DefaultButton, MessageBar, MessageBarType, Text } from "@fluentui/react";
import { withRouter } from "../../../utils/withRouter";
import { withTranslations } from "../../../utils/withTranslations";
import "./LiquidErrorBanner.scss";

@withTranslations
@withRouter
@inject("bannerStore")
@observer
export class LiquidErrorBanner extends React.Component {
  static propTypes = {
    bannerStore: PropTypes.object,
    body: PropTypes.string,
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    navigate: PropTypes.func,
    t: PropTypes.func,
  };

  closeBanner = () => {
    const { bannerStore } = this.props;
    bannerStore.setBanners([]);
  };

  onClick = () => {
    const { navigate } = this.props;

    this.closeBanner();
    navigate("/taskpane.html/errors", { replace: true });
  };

  render() {
    const { body, t } = this.props;

    return (
      <MessageBar
        className="LiquidErrorBanner"
        messageBarType={MessageBarType.error}
        onDismiss={this.closeBanner}
        isMultiline={true}
        dismissButtonAriaLabel="Close"
      >
        <Text>{body}</Text>
        <DefaultButton text={t("viewAll")} onClick={this.onClick} />
      </MessageBar>
    );
  }
}

export default LiquidErrorBanner;
