import * as React from "react";
import PropTypes from "prop-types";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import { BannerProvider, RootProvider } from "./providers";
import { AuthenticatedRoute, LeadSearchRoute } from "./routes";
import Progress from "./components/Progress";
import ErrorList from "./components/ErrorList/ErrorList";
import Login from "./components/Login/Login";
import LeadSearch from "./components/LeadSearch/LeadSearch";
import RequestEasyDocs from "./components/RequestEasyDocs/RequestEasyDocs";

export default class App extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      listItems: [],
    };
  }

  render() {
    const { isOfficeInitialized, title } = this.props;

    if (!isOfficeInitialized) {
      return (
        <Progress
          title={title}
          logo={require("./../../assets/wingmate-icon.svg")}
          message="Please sideload your addin to see app body."
        />
      );
    }

    return (
      <Router>
        <BannerProvider>
          <RootProvider>
            <Routes>
              <Route
                path="/taskpane.html/sign_in"
                element={
                  <div className="ms-welcome">
                    <Login />
                  </div>
                }
              />
              <Route path="/taskpane.html" element={<AuthenticatedRoute />}>
                <Route path="" element={<LeadSearchRoute />}>
                  <Route path="" element={<LeadSearch />} />
                  <Route path="errors" element={<ErrorList />} />
                </Route>
                <Route path="not_enabled" element={<RequestEasyDocs />} />
              </Route>
            </Routes>
          </RootProvider>
        </BannerProvider>
      </Router>
    );
  }
}

App.propTypes = {
  title: PropTypes.string,
  isOfficeInitialized: PropTypes.bool,
};
